import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import '../components/Map.css';
import {Map, SimpleSelectBox, MotifTree, PrivatePage, SearchButton} from '../components/index';
import { useAuth } from '../hooks/use-auth';

const URL = process.env.REACT_APP_API_URL || ''
export const MotifMapContext = createContext({} as any);

export type Rakugaki = {
  FY: string,
  date: string,
  file: string,
  latitude: string,
  longitude: string,
  motifid: string,
  motifname: string,
  rakugakiid: number,
  season: string,
}
export function MotifMap() {
  const [rakugaki_list, setRakugakiList] = useState(Array<Rakugaki>);
  const [motifs, setMotifs] = useState(Array<string>);
  const [season, setSeason] = useState('');
  const [selected, setSelected] = useState(Array<Rakugaki>);
  const auth = useAuth();
  useEffect(() => {
    auth.refreshIdTokenIfExpired();
    const getRakugaki = async () => {
      const endpoint = `${URL}/rakugaki-all`
      const headers = {
        headers: {
          Authorization: auth.id_token
        }
      }
      const res = await axios.get(endpoint, headers);
      setRakugakiList(JSON.parse(res.data.body))
    };
    getRakugaki()
  }, [])

  if (auth.isLoading) {
    return <div></div>;
  }

  const mapRefresh = () => {
    const selected_fy = season.split('_')[0]
    const selected_season = season.split('_')[1]
    const selected_rakugaki_list = rakugaki_list.filter(r =>
      r.FY === selected_fy && r.season === selected_season &&
      (motifs.includes(r.motifid) || motifs[0] === '00')
    )
    if (selected_rakugaki_list.length > 0) {
      // 地図のピン再描画
      setSelected(selected_rakugaki_list)
    } else {
      setSelected([])
      return alert("該当するデータがありません。条件を変更してください。");
    }
  }

  return (
    <PrivatePage>
      <div id="searchForm">
        <div className="motifmapTitle">落書きモチーフマップシステム</div>
        <form>
          <div className="searchArea">
            <div>
              <table>
                <tbody>
                  <MotifMapContext.Provider value={{rakugaki_list, motifs, setMotifs, season, setSeason,}}>
                    <tr>
                      <th>撮影時期</th>
                      <td>
                        <SimpleSelectBox />
                      </td>
                      <td width="20px"></td>
                      <th>モチーフ</th>
                      <td>
                        <MotifTree />
                      </td>
                    </tr>
                  </MotifMapContext.Provider>
                </tbody>
              </table>
            </div>
            <div className="btnArea">
              <SearchButton selectedShot={season} selectedMotif={motifs} onClick={() => mapRefresh()} />
            </div>
          </div>
        </form>
      </div>
      <Map markerData={selected} />
    </PrivatePage>
  )
}
