import { Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { MotifMapContext, Rakugaki } from '../pages/MotifMap';

const { Option } = Select;

interface SeasonDict {
  [key: string]: string
}
const season_dict: SeasonDict = {
  '0': '消去前',
  '1': '消去期間中',
  '2': '消去後',
}

const SimpleSelectBox: React.FC = () => {
  const { rakugaki_list, setSeason } = useContext(MotifMapContext);
  const [uniqued_seasons, setUniquedSeasons] = useState(Array<string>);
  useEffect(() => {
    setUniquedSeasons(Array.from(new Set<string>(rakugaki_list.map((r: Rakugaki) => [r.FY, r.season].join('_')))).sort())
  }, [rakugaki_list])

  return (
    <>
      <Select onChange={(v) => setSeason(v)} style={{ width: 200 }} placeholder ={'選択してください。'} >
        {uniqued_seasons.map((r: string) => {
          return (
            <Option value={r} key={r}>
              {r.split('_')[0]}年度 {season_dict[r.split('_')[1]]}
            </Option>
          )
        })}
      </Select>
    </>
  )
}

export default SimpleSelectBox;
