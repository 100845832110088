import { useContext } from 'react';
import { TreeSelect } from 'antd';
import { Motif } from '../constants/motif';
import { MotifMapContext } from '../pages/MotifMap';

const { SHOW_PARENT } = TreeSelect;
const treeData = Motif;

export const MotifTree: React.FC = () => {
    const { motifs, setMotifs } = useContext(MotifMapContext);

    const onChange = (newValue: never[]) => {
      setMotifs(newValue);
    };

    const tProps = {
      treeData,
      motifs,
      onChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: '選択してください。',
      treeDefaultExpandAll: true,
      listHeight:500,
      style: {
        width: '100%',
        minWidth: 200,
      },
    };
    return <TreeSelect {...tProps} />
}

export default MotifTree;
