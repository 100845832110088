import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import "../App.css";

export function SignIn() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [user_id, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const executeSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const result = await auth.signIn(user_id, password);
    if (result.success) {
      navigate({ pathname: '/map' });
    } else {
      alert("※入力されたユーザIDとパスワードに誤りがあります");
    }
  };

  return (
    <div className="formContainer">
      <form noValidate onSubmit={executeSignIn}>
        <h1>渋谷区<br />落書きモチーフマップ<br />システム</h1>
        <hr />
        <div className="uiForm">
          <div className="formField">
            <label htmlFor="user_id">ユーザID: </label>
            <input
              id="user_id"
              type="email"
              value={user_id}
              onChange={(e) => setUserId(e.target.value)}
            />
          </div>
          <div className="formField">
            <label htmlFor="password">パスワード: </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="submitButton">ログイン</button>
        </div>
      </form>
    </div>
  );
}
