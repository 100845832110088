import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

type PageProps = {
  children: React.ReactNode;
};
const PrivatePage: React.FC<PageProps> = (props) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <>{props.children}</> : <Navigate to="/" />;
};

export default PrivatePage;
