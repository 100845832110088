import React from 'react'

type Props = {
    selectedShot: string
    selectedMotif: string[]
    onClick: any
}

const SearchButton: React.FC<Props> = (props) => {
    const Search  = () => {
        const selectedShot = props.selectedShot;
        const selectedMotif = props.selectedMotif;

        if (selectedShot && selectedMotif && selectedMotif.length > 0) {
            props.onClick();
        } else {
            return alert("表示条件が未選択です。選択後に表示ボタンを押下してください。");
        }

    }

    return (
        <button type="button" className="btn btn-border btn-position" onClick={() => Search()}>表示</button>
    );
}

export default SearchButton;
