export const Motif = [
  {
    title: '全て',
    value: '00',
    key: '00',
    children: [
      { title: 'CODM',     value: '01', key: '01' },
      { title: 'ENS',      value: '02', key: '02' },
      { title: 'MINT',     value: '03', key: '03' },
      { title: 'SCOTCH',   value: '04', key: '04' },
      { title: 'SOS',      value: '05', key: '05' },
      { title: 'STEP',     value: '06', key: '06' },
      { title: 'TECK',     value: '07', key: '07' },
      { title: 'WANT246',  value: '08', key: '08' },
      { title: 'WOOF',     value: '09', key: '09' },
      { title: 'ebichiri', value: '10', key: '10' },
      { title: 'SOS2',     value: '11', key: '11' },
      { title: 'ECY',      value: '12', key: '12' },
    ],
  },
];
