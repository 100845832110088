import {useState, useEffect} from 'react'
import { MapContainer,   TileLayer,
  Marker, Popup, LayerGroup, Tooltip, ZoomControl } from 'react-leaflet'

import './Map.css';
import L from 'leaflet';
L.Icon.Default.imagePath = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/'


const Map = (props) => {
  // 緯度軽度
  const position = [35.65933, 139.70052]; // 中心地：渋谷駅
  // 初期マップズームレベル
  const zoom = 16;
  const [markerlist, setMarkerlist] = useState();

  useEffect(() => {
    setMarkerlist(props.markerData.map(targetRecord => {
      const latlong = [Number(targetRecord.latitude), Number(targetRecord.longitude)];
      const iconImage =  new LeafIcon({iconUrl: './icon/icon' + targetRecord.motifid + '.png'});
      const imgFilePath = "./images/" + targetRecord.file;
      return (
        <div key={targetRecord.rakugakiid}>
          <Marker position={latlong} icon={iconImage}>
            <Popup maxWidth={450} >
              {targetRecord.motifname}<br />
              <img src={imgFilePath} alt={targetRecord.motifname} width='400' height='280' />
            </Popup>
            <Tooltip>{targetRecord.motifname}</Tooltip>
          </Marker>
        </div>
        );
    }));
  }, [props.markerData])


  // アイコンサイズの設定など
  var LeafIcon = L.Icon.extend({
    options: {
    iconSize: [30, 41], // 今回のアイコンが細長いため縦横比を調整
    iconAnchor: [10, 41],
    popupAnchor: [3, -40]
    }
  });

  return (
    <MapContainer center={position} zoom={zoom} zoomControl={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LayerGroup>
        {markerlist}
      </LayerGroup>
      <ZoomControl position="bottomright" />

    </MapContainer>
  )
};

export default Map;
