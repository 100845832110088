import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { useAuth } from './hooks/use-auth';
import { SignIn } from './pages/SignIn';
import { MotifMap } from './pages/MotifMap';

function App() {
  const auth = useAuth();

  if (auth.isLoading) {
    return <div></div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<SignIn />} />
        <Route path="map" element={<MotifMap />} />
        <Route path="*" element={<p>Page Not Found</p>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
