import Amplify, { Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import AwsConfigAuth from '../aws-config/auth';

Amplify.configure({ Auth: AwsConfigAuth });

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  user_id: string;
  id_token: string;
  signIn: (user_id: string, password: string) => Promise<Result>;
  refreshIdTokenIfExpired: () => void;
  signOut: () => void;
}

interface Result {
  success: boolean;
  message: string;
}

const authContext = createContext({} as UseAuth);

type AuthProps = {
  children: React.ReactNode;
};
export const ProvideAuth: React.FC<AuthProps> = (props) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{props.children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user_id, setUserId] = useState('');
  const [id_token, setIdToken] = useState('');

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((result) => {
        setUserId(result.user_id);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setUserId('');
        setIsAuthenticated(false);
        setIsLoading(false);
      });
    refreshIdTokenIfExpired();
  }, []);

  const signIn = async (user_id: string, password: string) => {
    try {
      const result = await Auth.signIn(user_id, password);
      setUserId(result.user_id);
      setIsAuthenticated(true);
      refreshIdTokenIfExpired();
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const refreshIdTokenIfExpired = () => {
    Auth.currentSession()
      .then(s => {
        setIdToken(s.getIdToken().getJwtToken())
      })
      .catch(() => {
        setIdToken('')
      });

  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUserId('');
      setIsAuthenticated(false);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'ログアウトに失敗しました。',
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    user_id,
    id_token,
    signIn,
    refreshIdTokenIfExpired,
    signOut,
  };
};
